import React from "react"
import Index from "../components/Cities/index";
export default function Ks(props) {
  const mainUrl = props.params["*"];
  const url = props.params["*"] ? props.params["*"].split("-") : [];

  const cityNames = url.slice(2,-1)
  cityNames.forEach((name, i) => {
    cityNames[i] = name.charAt(0)?.toUpperCase()+name?.slice(1)
  });
  const cityFullName = cityNames.join(' ')
  const state = url.length >= 5 ? url[url.length-1] : url[3];
  return (
    <Index
      city={cityFullName ? cityFullName : "KS"}
      state={state?.toUpperCase() ? state?.toUpperCase() : "Arlington Heights"}
      url={mainUrl ? mainUrl : "/index"}
    />
  );
}
